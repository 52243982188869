import React from 'react';
import {Link} from "react-router-dom";
import {BsSearch} from "react-icons/bs"
import {FiMoreHorizontal} from "react-icons/fi"
// import {AiFillCaretDown} from "react-icons/ai"
import HTable from "./HTable";

const Index = () => {
   return (
       <div className="col-lg-12">
          <div className="shade-block p-4">
             <div className="d-flex justify-content-between">
                <h4 className="p-3">Ученики</h4>
                <Link to="/journal" className="top-part-horizontal p-3"><FiMoreHorizontal/></Link>
             </div>
             <div className="d-flex">
                <div className="search-header mb-4">
                   <div className="input-group border-bottom">
                      <input type="search" placeholder="Поиск" aria-describedby="search"
                             className="form-control bg-none border-0"/>
                      <div className="input-group-append border-0">
                         <button id="search" type="button" className="btnSearch">
                            <BsSearch/>
                         </button>
                      </div>
                   </div>
                </div>
                <div className="search-header search-header-class">
                   <div className="input-group border-bottom">

                      <form onSubmit={e => e.preventDefault()}>
                         <div className="input-group-append border-0">
                            <select name="classes class-select" id="classes">
                               <option value="Класс">Класс</option>
                               <option value="5А">5 А</option>
                               <option value="6В">6 В</option>
                               <option value="4Е">4 Е</option>
                               <option value="9З">9 З</option>
                            </select>
                            
                            {/*<button id="search" type="submit" className="btnSearch">*/}
                            {/*   <AiFillCaretDown/>*/}
                            {/*</button>*/}
                         </div>
                      </form>

                   </div>
                </div>
             </div>

             <HTable/>

             <nav aria-label="Пагинация">
                <ul className="pagination custom">
                   <li className="page-item"><Link className="page-link" to="/">Назад</Link></li>
                   <li className="page-item"><Link className="page-link active" to="/">1</Link></li>
                   <li className="page-item"><Link className="page-link" to="/">2</Link></li>
                   <li className="page-item"><Link className="page-link" to="/">3</Link></li>
                   <li className="page-item"><Link className="page-link" to="/">Вперёд</Link></li>
                </ul>
             </nav>

          </div>
       </div>
   );
};

export default Index;