//URL

// const CORS = 'https://cors-anywhere.herokuapp.com/';

//https://cors-anywhere.herokuapp.com/

const URL = `http://10.2.1.230:8000`;

// User
const USER_CREATE_PHONECODE = 'USER_CREATE_PHONECODE';
const USER_VERIFY_PHONECODE = 'USER_VERIFY_PHONECODE';
const USER_UPDATE_PHONECODE = 'USER_UPDATE_PHONECODE';

export {
   USER_CREATE_PHONECODE,
   USER_VERIFY_PHONECODE,

   URL
}