import React from 'react';
import {Link} from "react-router-dom";
import {FiMoreHorizontal} from "react-icons/fi"

const HTable = () => {
   return (
       <div className="table-animated-wrapper">
          <table className="table table-striped table-animated">
             <thead className="thead-light">
             <tr>
                <th scope="col">ID</th>
                <th scope="col">Имя Фамилия</th>
                <th scope="col">Пол</th>
                <th scope="col">Класс</th>
                <th scope="col">Секция</th>
                <th scope="col">Дата рождения</th>
                <th scope="col">Телефон</th>
                <th scope="col"></th>
             </tr>
             </thead>
             <tbody>
             <tr>
                <th scope="row">#001</th>
                <td>Шокиров Исмоил</td>
                <td>Мужской</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#002</th>
                <td>Акборов Комилжон</td>
                <td>Мужской</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#003</th>
                <td>Дониёрова Ирода</td>
                <td>Женский</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#004</th>
                <td>Александров Лев</td>
                <td>Мужской</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#005</th>
                <td>Быкова Ирина</td>
                <td>Женский</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#006</th>
                <td>Бородина Анна</td>
                <td>Женский</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#007</th>
                <td>Борисов Мирослав</td>
                <td>Мужской</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#008</th>
                <td>Бессонов Илья</td>
                <td>Мужской</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#009</th>
                <td>Афанасьева Варвара</td>
                <td>Женский</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#0010</th>
                <td>Анисимов Артём</td>
                <td>Мужской</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             <tr>
                <th scope="row">#0011</th>
                <td>Лаптева Анна</td>
                <td>Женский</td>
                <td>5</td>
                <td>А</td>
                <td>05/07/2009</td>
                <td>+998937856738</td>
                <td>
                   <Link
                       className="top-part-horizontal"
                       to="/homework/list/1/detail/1"
                   >
                      <FiMoreHorizontal/>
                   </Link>
                </td>
             </tr>
             </tbody>
          </table>
       </div>
   );
};

export default HTable;