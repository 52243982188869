import React, {Component} from 'react';
import {Link} from "react-router-dom";
import PlansTable from "./PlansTable";
import {Form} from "react-bootstrap";
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

class Plans extends Component {
   render() {
      return (
          <div className="container-fluid p-5">

             <MyHelmet title="Поурочные планы" content="Поурочные планы" />

             <div className="row">

                <div className="col-12">
                   <h4>Поурочные планы</h4>
                </div>

                <div className="col-12">

                   <div className="d-flex w-100">
                      <Form className="journal-filter" onSubmit={(e) => e.preventDefault()}>

                         <Form.Row>
                            <Form.Group controlId="choose-subject">
                               <Form.Control as="select" custom>
                                  {/*<option value={0}>Выберите ...</option>*/}
                                  <option value={1}>Алгебра</option>
                                  <option value={2}>Геометрия</option>
                                  <option value={3}>Математика</option>
                                  <option value={4}>Дискретная математика</option>
                                  <option value={5}>Линейная алгебра</option>
                               </Form.Control>
                            </Form.Group>
                            <Form.Group className="ml-lg-3" controlId="choose-class">
                               <Form.Control as="select" custom>
                                  <option>9Е</option>
                                  <option>11Б</option>
                                  <option>3Г</option>
                                  <option>10З</option>
                                  <option>5А</option>
                               </Form.Control>
                            </Form.Group>

                         </Form.Row>
                      </Form>
                      <div className="create-plan-btn">
                         <Link className="btn btn-outline-secondary" to="/plans/create">Добавить</Link>
                      </div>
                   </div>
                </div>

             </div>

             <div className="row">
                <div className="col-12">
                   <PlansTable/>
                </div>
             </div>

          </div>
      );
   }
}

export default Plans;