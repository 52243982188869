import React, {Component} from 'react';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import CodeSend from "./CodeSend";
import {connect} from "react-redux";
import CodeApprove from "./CodeApprove";

class Register extends Component {

   state = {
      expireTime: 0
   };

   static getDerivedStateFromProps(nexProps, prevState) {
      if (nexProps.user.createCode && nexProps.user.createCode.expiration_time === 180) {
         return ({expireTime: nexProps.user.createCode.expiration_time})
      }
      else {
         return null
      }
   }

   render() {
      return (
          <Modal
              open={this.props.isOpen}
              onClose={() => this.props.setOpen(false)}
              center
              classNames={{
                 // animationIn: 'customEnterAnimation',
                 // animationOut: 'customLeaveAnimation',
              }}
          >
             <div className="register-modal d-flex justify-content-center align-items-center">
                <div className="w-100">
                   <h3>Регистрация</h3>
                {
                   this.state.expireTime ? <CodeApprove expTime={this.state.expireTime} /> : <CodeSend />
                }
                </div>
             </div>

          </Modal>
      );
   }
}

function mapStateToProps(state) {
   return {
      user: state.user_r
   }
}

export default connect(mapStateToProps)(Register);