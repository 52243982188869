import React, { Component } from "react";
import { connect } from "react-redux";
// import { auth } from "../store/actions/user";


// Auth(Component, reload)

// This is an HOC component, on every route change I check if user is authenticated

// reload true means that if user is not authenticated it will be redirected to login page
// if reload is false it means user is already logged in and redirected to his dashboard
// if there is no reload user can access those pages without authentication

export default function (ComposedClass, reload) {

    class AuthenticationCheck extends Component {

        state = {
            loading: true
        };

        //// checking if user is authenticated
        componentDidMount() {
            window.scrollTo(0, 0);

            if (this.props.user.authLogin && this.props.user.authLogin.id) {
                this.setState({ loading: false });
            } else {
                // this.props.dispatch(auth());
                // this.setState({ loading: false });
            }
        }

        static getDerivedStateFromProps(nextProps, prevState) {

            if (nextProps.user.authLogin && nextProps.user.authLogin.isAuth) {
                const isAuth = nextProps.user.authLogin.isAuth;
                if (!isAuth) {
                    if (reload) {
                        nextProps.history.push("/login")
                    }
                } else {
                    if (reload === false) {
                        nextProps.history.push("/profile")
                    }
                }
                return ({ loading: false })
            }
            return null
        }

        // UNSAFE_componentWillReceiveProps(nextProps) {

        //     this.setState({ loading: false });

        //     if (!nextProps.user.authLogin.isAuth) {
        //         if (reload) {
        //             this.props.history.push("/login");
        //         }
        //     } else {
        //         if (reload === false) {
        //             this.props.history.push("/profile")
        //         }
        //     }
        // }

        render() {
            if (this.state.loading) {
                return <ComposedClass {...this.props} user={this.props.user} />
            }
            return (
                <React.Fragment>
                    <ComposedClass {...this.props} user={this.props.user} />
                </React.Fragment>
            )
        }
    }

    function mapStateToProps(state) {
        return {
            user: state.user_r
        }
    }

    return connect(mapStateToProps)(AuthenticationCheck);
}