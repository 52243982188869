import React, {Component} from 'react';
import {createPhoneCode} from "../../store/actions/user";
import {connect} from "react-redux";

class CodeSend extends Component {

   state = {
      phone: "",
      errorMessage: "",
      successMessage: "",
      warningMessage: ""
   };

   static getDerivedStateFromProps(nexProps, prevState) {
      if (nexProps.user.createCode && nexProps.user.createCode.expiration_time === 180) {
         return ({successMessage: nexProps.user.createCode.message})
      } else if (nexProps.user.createCode && nexProps.user.createCode.message.length) {
         return ({warningMessage: nexProps.user.createCode.message})
      }
      return null
   }

   setPhone = (e) => {
      this.setState({phone: e.target.value})
   };

   submitForm = (e) => {
      e.preventDefault();
      this.setState({
         phone: this.state.phone.trim()
      });
      if (this.state.phone.length === 12) {
         let reg = new RegExp('^(998)(90|91|93|94|95|97|98|99)[0-9]{7}$');
         if (reg.test(this.state.phone)) {
            this.props.dispatch(createPhoneCode(this.state.phone));
            this.setState({errorMessage: ""})
         } else {
            this.setState({errorMessage: "Неверный формат! (пример: 998901231212)"})
         }
      } else {
         this.setState({errorMessage: "Неверный формат! (пример: 998901231212)"})
      }
   };

   render() {

      const state = this.state;

      return (
          <React.Fragment>
             <form onSubmit={this.submitForm}>
                <div className="form-group">
                   <label htmlFor="phoneN">Номер телефона</label>
                   <input
                       id="phoneN"
                       className="input form-control"
                       minLength={12}
                       maxLength={15}
                       type="text"
                       name="phone"
                       placeholder="Номер телефона"
                       value={this.state.phone}
                       onChange={(e) => this.setPhone(e)}
                       // autoComplete="off"
                   />
                </div>
                <button className="btn btn-success" type="submit">Отправить код</button>
             </form>

             {
                state.errorMessage.length || state.warningMessage.length
                || state.successMessage.length ?
                    <div className="alert alert-warning" role="alert">
                       {
                          state.errorMessage.length ?
                              `${state.errorMessage}` : state.successMessage.length
                              ? `${state.successMessage}`
                              : `${state.warningMessage}`
                       }
                    </div>
                    : null
             }
          </React.Fragment>
      );
   }
}

function mapStateToProps(state) {
   return {
      user: state.user_r
   }
}

export default connect(mapStateToProps)(CodeSend);