import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Form, Col, Row, Button} from "react-bootstrap"

class DetailPage extends Component {
   render() {
      return (
          <div className="container-fluid">
             <div className="row">
                <div className="col-lg-12">

                   <div className="card-wrapper p-sm-2 p-md-3 p-lg-4 p-xl-5">
                      <div className="card">
                         <div className="card-body">
                            <h5 className="card-title">Шокиров Исмоил Шавкатович</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Класс: 9 "Е"</h6>
                            <p className="card-text">Алгебра: "Дроби и целые числа"</p>

                            {/*
                            <Form>
                               <Form.Group controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Комментарий</Form.Label>
                                  <Form.Control name="hw-comment" as="textarea" rows="3"/>
                               </Form.Group>
                               <Form.Group as={Row}>
                                  <Form.Label as="legend" column sm={2}>
                                     Оценка
                                  </Form.Label>
                                  <Col sm={10}>
                                     <Form.Check
                                         type="radio"
                                         label="5 - Отлично"
                                         name="formHorizontalRadios"
                                         id="formHorizontalRadios1"
                                     />
                                     <Form.Check
                                         type="radio"
                                         checked
                                         label="4 - Хорошо"
                                         name="formHorizontalRadios"
                                         id="formHorizontalRadios2"
                                     />
                                     <Form.Check
                                         type="radio"
                                         label="3 - Удовлетварительно"
                                         name="formHorizontalRadios"
                                         id="formHorizontalRadios3"
                                     />
                                     <Form.Check
                                         type="radio"
                                         label="2 - Неудовлетварительно"
                                         name="formHorizontalRadios"
                                         id="formHorizontalRadios4"
                                     />
                                  </Col>
                               </Form.Group>

                               <div className="d-flex justify-content-between">

                                  <div className="d-flex align-items-end">
                                     <span className="mr-4">Добавлено: <em>18.08.2020</em></span>
                                     <Link
                                         className="btn btn-outline-dark"
                                         to="/hello.docx"
                                         target="_blank"
                                         download
                                     >Скачать файл</Link>
                                  </div>

                                  <Button
                                      className="btn btn-primary"
                                      type="submit">Сохранить
                                  </Button>
                               </div>


                            </Form>
                            */}
                            <p>
                               Комментарий: Дониёр Рахимович я выполнил домашнее задание,
                               по математике. Прошу вас проверить и поставить мне 5
                            </p>
                            <div className="d-flex align-items-center">
                               <span className="mr-4">Добавлено: <em>18.08.2020</em></span>
                               <Link
                                   className="btn btn-outline-dark"
                                   to="/hello.docx"
                                   target="_blank"
                                   download
                               >Скачать файл</Link>
                            </div>
                            <div className="mt-3">
                               <Form>
                                  <Form.Group as={Row}>
                                     <Form.Label as="legend" column sm={2}>
                                        Оценка:
                                     </Form.Label>
                                     <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="5 - Отлично"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios1"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="4 - Хорошо"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios2"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="3 - Удовлетварительно"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios3"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="2 - Неудовлетварительно"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios4"
                                        />
                                     </Col>
                                  </Form.Group>

                                  <div className="d-flex justify-content-end">
                                     <Button className="btn btn-success" type="submit">Сохранить</Button>
                                  </div>

                               </Form>
                            </div>

                         </div>
                      </div>


                      <Link className="btn btn-secondary mt-3" to="/homework/list/1">Назад</Link>

                   </div>
                </div>
             </div>


          </div>
      );
   }
}

export default DetailPage;