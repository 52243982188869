import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {FaCalculator, FaPencilAlt} from "react-icons/fa"
import {BsBook,BsFillPeopleFill} from "react-icons/bs"
import { MdHome, MdEventNote} from "react-icons/md"
import {AiOutlineSchedule} from "react-icons/ai"
import {IoIosChatbubbles} from "react-icons/io"

const SidebarList = (props) => {

   const items = [
      {
         to: "/",
         text: "Главная",
         icon: <MdHome className={props.open ? '' : 'sidebar-opened'}/>
      },
      {
         to: "/subject",
         text: "Предметы",
         icon: <BsBook className={props.open ? '' : 'sidebar-opened'}/>
      },
      {
         to: "/homework",
         text: "Домашние задания",
         icon: <FaCalculator className={props.open ? '' : 'sidebar-opened'}/>
      },
      {
         to: "/journal",
         text: "Журнал",
         icon: <FaPencilAlt className={props.open ? '' : 'sidebar-opened'}/>
      },
      {
         to: "/schedule",
         text: "Расписание",
         icon: <AiOutlineSchedule className={props.open ? '' : 'sidebar-opened'}/>
      },
      {
         to: "/students",
         text: "Ученики",
         icon: <BsFillPeopleFill className={props.open ? '' : 'sidebar-opened'} />
      },
      {
         to: "/plans",
         text: "Поурочные планы",
         icon: <MdEventNote className={props.open ? '' : 'sidebar-opened'} />
      },
      {
         to: "/chat",
         text: "Чат",
         icon: <IoIosChatbubbles className={props.open ? '' : 'sidebar-opened'} />
      },

   ];

   const renderLinks = (items) => (
       items.map((item, i) => (
           <li
               className={
                  item.to === props.location.pathname ? "active" :
                      item.to !== "/" ?
                          props.location.pathname.match(new RegExp(`${item.to}`, 'gi'))
                              ? 'active' : ''
                          : ''
               }
               key={i}
           >
              <Link to={`${item.to}`}>
                 {item.icon} {props.open ? `${item.text}` : ""}
              </Link>
           </li>
       ))
   );


   return (
       <ul>
          {renderLinks(items)}
       </ul>
   );
};

export default withRouter(SidebarList);