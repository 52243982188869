import React from 'react';
import {FiMoreHorizontal} from "react-icons/fi"
import {Link} from "react-router-dom";

const StatRight = () => {
   return (
       <div className="col-xl-4 col-lg-6 stat-right">
          <div className="schedule-mini shade-block">
             <div className="d-flex more justify-content-between">
                <h5>Расписание</h5>
                <Link to="/schedule">
                   <FiMoreHorizontal/>
                </Link>
             </div>
             <ul className="list-group-flush">
                <li className="list-group-item">
                   <div className="date">1 сентября, 2020</div>
                   <div className="info">
                      <div className="d-flex justify-content-between">
                         <h5>Математика</h5>
                         <span>09:00 - 09:45</span>
                      </div>
                      <div className="d-flex justify-content-between">
                         <p>Умножение дробных чисел</p>
                         <strong>5А класс</strong>
                      </div>
                   </div>
                </li>
                <li className="list-group-item">
                   <div className="date">18 августа, 2020</div>
                   <div className="info">
                      <div className="d-flex justify-content-between">
                         <h5>Геометрия</h5>
                         <span>10:00 - 10:45</span>
                      </div>
                      <div className="d-flex justify-content-between">
                         <p>Умножение дробных чисел</p>
                         <strong>8Б класс</strong>
                      </div>
                   </div>
                </li>
                <li className="list-group-item">
                   <div className="date">18 августа, 2020</div>
                   <div className="info">
                      <div className="d-flex justify-content-between">
                         <h5>Алгебра</h5>
                         <span>11:00 - 11:45</span>
                      </div>
                      <div className="d-flex justify-content-between">
                         <p>Умножение дробных чисел</p>
                         <strong>4В класс</strong>
                      </div>
                   </div>
                </li>
                <li className="list-group-item">
                   <div className="date">18 августа, 2020</div>
                   <div className="info">
                      <div className="d-flex justify-content-between">
                         <h5>Алгебра</h5>
                         <span>12:00 - 12:45</span>
                      </div>
                      <div className="d-flex justify-content-between">
                         <p>Умножение дробных чисел</p>
                         <strong>7Б класс</strong>
                      </div>
                   </div>
                </li>
             </ul>
          </div>
       </div>
   );
};

export default StatRight;