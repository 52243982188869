import React, {Component} from 'react'
import {MdSend, MdSearch} from "react-icons/md"
import MyHelmet from "../../widgets/MyHelmet/MyHelmet"

class Chat extends Component {
   render() {
      return (
          <div className="container-fluid chat pb-lg-5 pr-lg-5 pl-lg-5 pt-lg-4">

             <MyHelmet title="Чат" content="Общайтесь с коллегами в чате" />

             <div className="row no-gutters">
                <h4 className="mb-3">Чат</h4>
             </div>
             <div className="row no-gutters">
                <div className="col-lg-4 chat-left-side border-right">
                   <div className="settings-tray">
                      <div className="d-flex align-items-center">
                         <img className="profile-image"
                              src="/images/ba.jfif"
                              alt="Profile img"
                         />
                         <h6 className="ml-3">Bakhodir Akhmedov</h6>
                      </div>
                   </div>
                   <div className="search-box">
                      <div className="input-wrapper">
                         <MdSearch/>
                         <input placeholder="Поиск" type="text"/>
                      </div>
                   </div>
                   <div className="friend-drawer friend-drawer--onhover">
                      <img className="profile-image"
                           src="/images/Ula.jpg" alt=""/>
                      <div className="text">
                         <h6>Ula</h6>
                         <p className="text-muted">желаю удачи)))</p>
                      </div>
                      <span className="time text-muted small">13:21</span>
                   </div>
                   <hr/>
                   <div className="friend-drawer friend-drawer--onhover">
                      <img className="profile-image"
                           src="/images/ava1.png"
                           alt=""/>
                      <div className="text">
                         <h6>Юлия Алексеевна</h6>
                         <p className="text-muted">Вы закончили докладную?</p>
                      </div>
                      <span className="time text-muted small">00:32</span>
                   </div>
                   <hr/>
                   <div className="friend-drawer friend-drawer--onhover ">
                      <img className="profile-image"
                           src="/images/aliye.jfif"
                           alt=""/>
                      <div className="text">
                         <h6>Aliye Khalilova</h6>
                         <p className="text-muted">Я учитель по английскому:)</p>
                      </div>
                      <span className="time text-muted small">13:21</span>
                   </div>
                   <hr/>
                   <div className="friend-drawer friend-drawer--onhover">
                      <img className="profile-image"
                           src="/images/naseer.jfif" alt=""/>
                      <div className="text">
                         <h6>Доктор Насир</h6>
                         <p className="text-muted">В следующий раз я тебе отправлю ...</p>
                      </div>
                      <span className="time text-muted small">13:21</span>
                   </div>
                   <hr/>
                   <div className="friend-drawer friend-drawer--onhover">
                      <img className="profile-image"
                           src="/images/olimov.jfif"
                           alt=""/>
                      <div className="text">
                         <h6>Хусниддин Олимов</h6>
                         <p className="text-muted">Сегодня буду объяснять закон Ньютона</p>
                      </div>
                      <span className="time text-muted small">13:21</span>
                   </div>
                   <hr/>
                   <div className="friend-drawer friend-drawer--onhover">
                      <img
                          className="profile-image"
                          src="/images/ashesh.jfif"
                          alt=""
                      />
                      <div className="text">
                         <h6>Ashish Seth</h6>
                         <p className="text-muted">Я изучаю "Computer Networks"</p>
                      </div>
                      <span className="time text-muted small">13:21</span>
                   </div>
                </div>
                <div className="col-lg-8 chat-right-side">
                   <div className="settings-tray">
                      <div className="friend-drawer no-gutters friend-drawer--grey">
                         <img className="profile-image"
                              src="/images/Ula.jpg" alt=""/>
                         <div className="text">
                            <h6>Ulug'bek Mirziyoyev</h6>
                            {/*<p className="text-muted">Layin' down the law since like before Christ...</p>*/}
                         </div>
                      </div>
                   </div>
                   <div className="chat-panel">

                      <div className="chat-scrolling">
                         <div className="row no-gutters">
                            <div className="col-lg-4">
                               <div className="chat-bubble chat-bubble--left">
                                  Здравствуйте Баходир Анварович
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4 offset-lg-8">
                               <div className="chat-bubble chat-bubble--right">
                                  Добрый день
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4 offset-lg-8">
                               <div className="chat-bubble chat-bubble--right">
                                  Что нового в школе?
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4">
                               <div className="chat-bubble chat-bubble--left">
                                  у меня сейчас много работы
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4">
                               <div className="chat-bubble chat-bubble--left">
                                  надо подготовить доклад
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4">
                               <div className="chat-bubble chat-bubble--left">
                                  и ещё мне надо проверить 456 ДЗ по химии
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4 offset-lg-8">
                               <div className="chat-bubble chat-bubble--right">
                                  Хорошо, напишите мне когда закончите ваши дела
                               </div>
                            </div>
                         </div>
                         <div className="row no-gutters">
                            <div className="col-lg-4 offset-lg-8">
                               <div className="chat-bubble chat-bubble--right">
                                  желаю удачи)))
                               </div>
                            </div>
                         </div>
                      </div>
                         <div className="row">
                            <div className="col-12">
                               <div className="chat-box-tray">
                                  {/*<i className="material-icons">sentiment_very_satisfied</i>*/}
                                  <input type="text" placeholder="Напишите сообщение..."/>
                                  <MdSend/>
                               </div>
                            </div>
                         </div>
                   </div>
                </div>
             </div>
          </div>
      );
   }
}

export default Chat;