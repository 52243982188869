import React, {Component} from 'react'
import LoginForm from "./LoginForm";
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

export default class Login extends Component {

   render() {
      return (
          <section className="login-section">

             <MyHelmet title="Авторизация" content="Страница авторизации" />

             <div className="container wrapper">
                <div className="row login-main">
                   <div className="col-md-6">
                      <div className="login-form">
                         <div>
                            <div className="text-center">
                               <img className="login-form-logo planePath" src="https://bit.ly/3h4TeVY" alt={"logo"}/>
                            </div>
                            <div className="d-block text-center">
                               <strong>
                                        <span className="logo-text">
                                            Онлайн школа
                                        <span className="logo-desc"> Админ панель версия 1.0</span>
                                        </span>
                               </strong>
                            </div>
                         </div>
                          <LoginForm/>
                      </div>
                   </div>
                   <div className="col-md-6" style={{padding: "0"}}>
                      <div className="login-bg">
                         <div className="main-animate" style={{width: "100%"}}>

                            <img src="/images/s.svg" alt={"svg"}/>

                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </section>
      )
   }
}
