import React, {Component} from 'react';
import StudentsList from "./StudentsList";
import {BsSearch} from "react-icons/bs"
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

class Students extends Component {
   render() {
      return (
          <div className="container-fluid p-5">

             <MyHelmet title="Ученики" content="Страница учеников" />

             <div className="row">

                <div className="col-12">

                <div className="d-flex justify-content-between">
                   <h4>Ученики</h4>
                </div>
                   <div className="d-flex">
                      <div className="search-header mb-4">
                         <div className="input-group border-bottom">
                            <input type="search" placeholder="Поиск" aria-describedby="search"
                                   className="form-control bg-none border-0"/>
                            <div className="input-group-append border-0">
                               <button id="search" type="button" className="btnSearch">
                                  <BsSearch/>
                               </button>
                            </div>
                         </div>
                      </div>
                      <div className="search-header search-header-class">
                         <div className="input-group border-bottom">

                            <form onSubmit={e => e.preventDefault()}>
                               <div className="input-group-append border-0">
                                  <select name="classes class-select" id="classes">
                                     <option value="Класс">Класс</option>
                                     <option value="5А">5 А</option>
                                     <option value="6В">6 В</option>
                                     <option value="4Е">4 Е</option>
                                     <option value="9З">9 З</option>
                                  </select>
                               </div>
                            </form>

                         </div>
                      </div>
                   </div>
                </div>
             </div>

             <div className="row">
                <StudentsList/>
             </div>
          </div>
      );
   }
}

export default Students;