import React from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import CountUp from 'react-countup';

const StatCenter = () => {
   return (
       <div className="col-xl-4 col-lg-6 stat-center">
          <div className="pie-center-wrapper shade-block">
             <h4>Ученики</h4>
             <div className="pie-chart">
                <PieChart
                    data={[
                       {title: 'Мальчиков', value: 51, color: '#FFA602'},
                       {title: 'Девочек', value: 25, color: '#304FFF'}
                    ]}
                    lineWidth={35}
                    startAngle={-70}
                    // totalValue={100}
                    paddingAngle={2}
                    animate={true}
                    radius={50}
                    center={[50, 50]}
                    animationDuration={1000}
                />
             </div>
             <div className="pie-chart-bottom">
                <div className="left">
                   <div className="bar" style={{maxWidth: `50%`}}></div>
                   <div>
                      <span>Девочек</span>
                      <strong><CountUp end={25} /></strong>
                   </div>
                </div>
                <div className="center">

                </div>
                <div className="right">
                   <div className="bar" style={{maxWidth: `100%`}}></div>
                   <div>
                      <span>Мальчиков</span>
                      <strong><CountUp end={51} /></strong>
                   </div>
                </div>
             </div>
          </div>
       </div>
   );
};

export default StatCenter;