import React from 'react';

const PlansTable = () => {
   return (
       <table className="table table-plans">
          <thead className="thead-light">
          <tr>
             <th scope="col">#</th>
             <th scope="col">Наименование темы</th>
             <th scope="col">Кол-во часов</th>
             <th scope="col">Дата проведения</th>
             <th scope="col">Примечание</th>
          </tr>
          </thead>
          <tbody>
          <tr>
             <th scope="row">1</th>
             <td>Дробные числа</td>
             <td>12</td>
             <td>02.09.2020</td>
             <td>модель организационной деятельности требуют от нас
                анализа существенных финансовых и административных условий.
             </td>
          </tr>
          <tr>
             <th scope="row">2</th>
             <td>Аксиоматический метод</td>
             <td>11</td>
             <td>02.10.2020</td>
             <td>Равным образом рамки и место обучения кадров влечет за собой процесс внедрения и модернизации системы
                обучения кадров, соответствует насущным потребностям.
             </td>
          </tr>
          <tr>
             <th scope="row">3</th>
             <td>Формула Герона</td>
             <td>9</td>
             <td>12.09.2020</td>
             <td>Товарищи! постоянный количественный рост и сфера
                нашей активности представляет собой интересный эксперимент проверки модели развития. Не следует, однако
                забывать, что консультация с широким активом требуют определения и уточнения новых предложений.
             </td>
          </tr>
          <tr>
             <th scope="row">4</th>
             <td>Иррациональные неравенства и уравнения</td>
             <td>8</td>
             <td>16.09.2020</td>
             <td>Равным образом рамки и место обучения кадров влечет за собой процесс внедрения и модернизации системы
                обучения кадров, соответствует насущным потребностям. Товарищи! постоянный количественный рост и сфера
                нашей активности представляет собой интересный эксперимент проверки модели развития.
             </td>
          </tr>
          <tr>
             <th scope="row">5</th>
             <td>Логарифмические квадратные уравнения</td>
             <td>7</td>
             <td>18.10.2020</td>
             <td>Идейные соображения высшего порядка, а также новая модель организационной деятельности требуют от нас
                анализа существенных финансовых и административных условий. Идейные соображения высшего порядка, а также
                постоянный количественный рост и сфера нашей активности обеспечивает широкому кругу (специалистов)
                участие в формировании позиций, занимаемых участниками в отношении поставленных задач.
             </td>
          </tr>
          <tr>
             <th scope="row">6</th>
             <td>Дробные числа</td>
             <td>2</td>
             <td>18.10.2020</td>
             <td>Не следует, однако забывать, что постоянное информационно-пропагандистское обеспечение нашей
                деятельности представляет собой интересный эксперимент проверки систем массового участия.
             </td>
          </tr>
          <tr>
             <th scope="row">7</th>
             <td>Иррациональные неравенства и уравнения</td>
             <td>4</td>
             <td>17.07.2020</td>
             <td>Не следует, однако забывать, что постоянное информационно-пропагандистское обеспечение нашей
                деятельности представляет собой интересный эксперимент проверки систем массового участия.
             </td>
          </tr>
          <tr>
             <th scope="row">8</th>
             <td>Формула Герона</td>
             <td>9</td>
             <td>18.09.2020</td>
             <td>По своей сути рыбатекст является альтернативой традиционному lorem ipsum, который вызывает у некторых
                людей недоумение при попытках прочитать рыбу текст.
             </td>
          </tr>
          <tr>
             <th scope="row">9</th>
             <td>Аксиоматический метод</td>
             <td>6</td>
             <td>08.05.2020</td>
             <td>Идейные соображения высшего порядка, а также новая модель организационной деятельности требуют от нас
                анализа существенных финансовых и административных условий. Идейные соображения высшего порядка, а также
                постоянный количественный рост и сфера нашей активности обеспечивает широкому кругу (специалистов)
                участие в формировании позиций, занимаемых участниками в отношении поставленных задач.
             </td>
          </tr>
          <tr>
             <th scope="row">10</th>
             <td>Аксиоматический метод</td>
             <td>18</td>
             <td>18.10.2020</td>
             <td>В отличии от lorem ipsum, текст рыба на русском языке наполнит любой макет непонятным смыслом и придаст
                неповторимый колорит советских времен.
             </td>
          </tr>
          <tr>
             <th scope="row">11</th>
             <td>Аксиоматический метод</td>
             <td>5</td>
             <td>14.07.2020</td>
             <td>позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия.
             </td>
          </tr>
          </tbody>
       </table>
   );
};

export default PlansTable;