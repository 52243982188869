import React, {Component} from 'react';
import {Form} from "react-bootstrap"
import {Link} from "react-router-dom";
import {FiSettings} from "react-icons/fi";
import {BsPencilSquare} from "react-icons/bs";
import {FaBook} from "react-icons/fa";
import {MdSchool} from "react-icons/md";
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

class Homework extends Component {
   render() {
      return (
          <section>

             <MyHelmet title="Домашние задания учеников" content="Страница домашних заданий учеников" />

             <div className="container-fluid">
                <div className="row">
                   <div className="col-lg-12">

                      <div className="homework-wrapper p-sm-2 p-md-3 p-lg-4 p-xl-5">
                         <h4 className="mb-3">Домашние задания</h4>
                         <Form onSubmit={(e) => e.preventDefault()}>

                            <Form.Row>
                               <Form.Group controlId="choose-subject">
                                  <Form.Label><FaBook/> Выберите предмет</Form.Label>
                                  <Form.Control as="select" custom>
                                     {/*<option value={0}>Выберите ...</option>*/}
                                     <option value={1}>Алгебра</option>
                                     <option value={2}>Геометрия</option>
                                     <option value={3}>Математика</option>
                                     <option value={4}>Дискретная математика</option>
                                     <option value={5}>Линейная алгебра</option>
                                  </Form.Control>
                               </Form.Group>
                               <Form.Group className="ml-2" controlId="choose-class">
                                  <Form.Label><MdSchool/> Выберите класс</Form.Label>
                                  <Form.Control as="select" custom>
                                     <option>9Е</option>
                                     <option>11Б</option>
                                     <option>3Г</option>
                                     <option>10З</option>
                                     <option>5А</option>
                                  </Form.Control>
                               </Form.Group>
                            </Form.Row>
                         </Form>
                         <table className="table">
                            <thead className="thead-light">
                            <tr>
                               <th scope="col">#</th>
                               <th scope="col"><BsPencilSquare /> Наименование темы</th>
                               <th scope="col"><FiSettings /> Управление</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                               <th scope="row">1</th>
                               <td>Уравнения</td>
                               <td><Link to="/homework/list/1">Подробнее</Link></td>
                            </tr>
                            <tr>
                               <th scope="row">2</th>
                               <td>Круг и окружность</td>
                               <td><Link to="/homework/list/1">Подробнее</Link></td>
                            </tr>
                            <tr>
                               <th scope="row">3</th>
                               <td>Квадратные Уравнения</td>
                               <td><Link to="/homework/list/1">Подробнее</Link></td>
                            </tr>
                            <tr>
                               <th scope="row">4</th>
                               <td>Логарифмы</td>
                               <td><Link to="/homework/list/1">Подробнее</Link></td>
                            </tr>
                            <tr>
                               <th scope="row">5</th>
                               <td>Дроби и целые числа</td>
                               <td><Link to="/homework/list/1">Подробнее</Link></td>
                            </tr>
                            </tbody>
                         </table>
                      </div>

                   </div>
                </div>
             </div>

             {/*
             <div className="container-fluid">

                <div className="homework-main">
                   <div className="homework-scroll__main">
                      <div className="row">
                         <div className="col-3">
                            <a href="#" className="add-homework">
                               <div>
                                  <span>+</span>
                                  <span>Добавить новость</span>
                               </div>
                            </a>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Новый год</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     С Новым годом, уважаемые коллеги!
                                     Пусть сбываются наши мечты и легко
                                     решаются все поставленные задачи.
                                     Сложности пусть обходят наш дружный
                                     коллектив коллектив стороной,
                                     а карьерная лестница станет взлетной
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                         <div className="col-3">
                            <Link to="/subjects" className="homework-item">
                               <div className="title">
                                  <h2>Семинар</h2>
                               </div>
                               <div className="homework-item__content">
                                  <p>
                                     Уважаемые коллеги! Приглашаем Вас
                                     06.02.2019г. в 12:00 по адресу: Ташкент,
                                     гостиница Dedeman на семинар для
                                     врачей онкологов и гематологов
                                  </p>
                               </div>
                            </Link>
                         </div>
                      </div>
                   </div>
                </div>

             </div>
             */}
          </section>
      );
   }
}

export default Homework;