import React, {Component} from 'react';
import ReactDatePicker from "../../widgets/ReactDatePicker/ReactDatePicker";
import {BsCalendar} from "react-icons/bs"
import {IoMdTime} from "react-icons/io"
import {MdSchool} from "react-icons/md"
import {FaBook} from "react-icons/fa"
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

class Schedule extends Component {
   state = {
      date: ""
   };

   getDate = (date) => {
      this.setState({date})
   };

   render() {
      return (
          <div className="container-fluid">

             <MyHelmet title="Расписание" content="Расписание учителя" />

             <div className="p-sm-2 p-md-3 p-lg-4 p-xl-5">


                <div className="row">
                   <div className="col-lg-12">
                      <h4><BsCalendar/> Расписание</h4>
                   </div>
                </div>

                <div className="row">

                   <div className="col-lg-5">

                      <ReactDatePicker
                          className="custom-react-datepicker"
                          getDate={this.getDate}
                      />
                   </div>
                   <div className="col-lg-7">
                      <div className="schedule-table">

                         <table className="table">
                            <thead className="thead-light">
                            <tr>
                               <th scope="col">#</th>
                               <th scope="col"><IoMdTime/> Время</th>
                               <th scope="col"><FaBook/> Предмет</th>
                               <th scope="col"><MdSchool/> Класс</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                               <th scope="row">1</th>
                               <td>9:00 - 9:45</td>
                               <td>Математика</td>
                               <td>5А</td>
                            </tr>
                            <tr>
                               <th scope="row">2</th>
                               <td>9.50 – 10.35</td>
                               <td>Геометрия</td>
                               <td>6Б</td>
                            </tr>
                            <tr>
                               <th scope="row">3</th>
                               <td>10.40 – 11.25</td>
                               <td>Математика</td>
                               <td>7А</td>
                            </tr>
                            <tr>
                               <th scope="row">4</th>
                               <td>11.40 – 12.25</td>
                               <td>Математика</td>
                               <td>8Г</td>
                            </tr>
                            <tr>
                               <th scope="row">5</th>
                               <td>12.30 – 13.50</td>
                               <td>Геометрия</td>
                               <td>9А</td>
                            </tr>

                            </tbody>
                         </table>

                      </div>
                   </div>

                </div>

             </div>
          </div>
      );
   }
}

export default Schedule;