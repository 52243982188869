import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class Footer extends Component {

   render() {
      return (
          <footer>
             <div className="container-fluid">
                <div className="row">
                   <div className="col-12">

                      <div className="footer-main">
                         <div className="copy">
                        <span>
                            © {new Date().getFullYear()}. Все права защищены
                        </span>
                         </div>
                         <div className="development">
                            <span>Разработка: СДО</span>
                         </div>
                      </div>

                   </div>
                </div>
             </div>
          </footer>
      );
   }
}


export default withRouter(Footer);