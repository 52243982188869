import React, {Component} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import ReactDatePicker from "../../widgets/ReactDatePicker/ReactDatePicker";
import {MdSchool} from "react-icons/md"
import {MdDateRange} from "react-icons/md"
import {BsPeopleCircle} from "react-icons/bs"
import {FiCheckSquare} from "react-icons/fi"
import {FaBook,FaSchool,FaHome} from "react-icons/fa";
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

class Journal extends Component {

   state = {
      date: ""
   };

   getDate = (date) => {
      this.setState({date})
   };


   render() {
      return (
          <div className="container-fluid">

             <MyHelmet title="Журнал учителя" content="Журнал учителя" />

             <div className="row">
                <div className="col-lg-12">

                   <div className="journal-form-wrapper p-sm-2 p-md-3 p-lg-4 p-xl-5">
                      <h4 className="mb-3">Журнал</h4>
                      <Form className="journal-filter" onSubmit={(e) => e.preventDefault()}>

                         <Form.Row>
                            <Form.Group controlId="choose-subject">
                               <Form.Label><FaBook/> Выберите предмет</Form.Label>
                               <Form.Control as="select" custom>
                                  {/*<option value={0}>Выберите ...</option>*/}
                                  <option value={1}>Алгебра</option>
                                  <option value={2}>Геометрия</option>
                                  <option value={3}>Математика</option>
                                  <option value={4}>Дискретная математика</option>
                                  <option value={5}>Линейная алгебра</option>
                               </Form.Control>
                            </Form.Group>
                            <Form.Group className="ml-lg-3" controlId="choose-class">
                               <Form.Label><MdSchool/> Выберите класс</Form.Label>
                               <Form.Control as="select" custom>
                                  <option>9Е</option>
                                  <option>11Б</option>
                                  <option>3Г</option>
                                  <option>10З</option>
                                  <option>5А</option>
                               </Form.Control>
                            </Form.Group>

                            <Form.Group className="ml-xl-3 filter-date" controlId="choose-date">
                               <Form.Label><MdDateRange/> Выберите дату</Form.Label>
                               <ReactDatePicker
                                   getDate={this.getDate}
                                   settings={{inline: false, dateFormat: "P"}}
                               />
                            </Form.Group>

                         </Form.Row>
                      </Form>

                      <Form onSubmit={(e) => e.preventDefault()}>

                         <div className="journal-table">
                            <table className="table">
                               <thead className="thead-light">
                               <tr>
                                  <th scope="col">#</th>
                                  <th scope="col"><BsPeopleCircle/> Ф.И.О</th>
                                  <th scope="col"><FaSchool/> Ответ в классе</th>
                                  <th scope="col"><FaHome/> Домашнее задание</th>
                                  <th scope="col"><FiCheckSquare/> Присутствие</th>
                               </tr>
                               </thead>
                               <tbody>
                               <tr>
                                  <th scope="row">1</th>
                                  <td>Шокиров Исмоил Шавкатович</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">2</th>
                                  <td>Акборов Комилжон Авазович</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">3</th>
                                  <td>Дониёрова Ирода Фазилжановна</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">4</th>
                                  <td>Александров Лев Матвеевич</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">5</th>
                                  <td>Быкова Ирина Тимофеевна</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">6</th>
                                  <td>Бородина Анна Романовна</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">7</th>
                                  <td>Борисов Мирослав Ярославович</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">8</th>
                                  <td>Бессонов Илья Кириллович</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">9</th>
                                  <td>Афанасьева Варвара Георгиевна</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">10</th>
                                  <td>Анисимов Артём Кириллович</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               <tr>
                                  <th scope="row">11</th>
                                  <td>Лаптева Анна Степановна</td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td>
                                     <Form.Group className="p-0" as={Col} controlId="classGrade">
                                        <Form.Control type="number" min={1} max={5}/>
                                     </Form.Group>
                                  </td>
                                  <td><Form.Check id="check-1" type="checkbox"/></td>
                               </tr>
                               </tbody>
                            </table>
                         </div>

                         <Button className="btn btn-primary" type="submit">Сохранить</Button>

                      </Form>

                   </div>

                </div>
             </div>
          </div>
      );
   }
}

export default Journal;