import React from 'react';
import {Switch, Route} from "react-router-dom";

import NotFound from "./components/404/NotFound";
import Home from "./containers/Home/Home"
import Login from "./containers/Login/Login"
import Auth from "./hoc/auth";
import Layout from "./hoc/layout";
import Subjects from "./containers/Subjects/Subjects";
import Homework from "./containers/Homework/Homework";
import ListOfThemes from "./containers/Subjects/SubjectThemes/ListOfThemes";
import CreateTheme from "./containers/Subjects/SubjectThemes/CreateTheme";
import DetailList from "./containers/Homework/Detail/DetailList";
import DetailPage from "./containers/Homework/Detail/DetailPage";
import Schedule from "./containers/Schedule/Schedule";
import Journal from "./containers/Journal/Journal";
import Chat from "./containers/Chat/Chat";
import Students from "./containers/Students";
import Plans from "./containers/Plans";
import CreatePlan from "./containers/Plans/CreatePlan";

const Routes = () => {
   return (
       <Layout>
          <Switch>
             <Route path="/login" exact component={Auth(Login, false)}/>
             <Route path="/subject" exact component={Auth(Subjects, null)}/>
             <Route path="/subject/themeList/:id" exact component={Auth(ListOfThemes, null)}/>
             <Route path="/subject/createTheme/:id" exact component={Auth(CreateTheme, null)}/>
             <Route path="/homework" exact component={Auth(Homework, null)}/>
             <Route path="/schedule" exact component={Auth(Schedule, null)}/>
             <Route path="/homework/list/:id" exact component={Auth(DetailList, null)}/>
             <Route path="/homework/list/:id/detail/:id" exact component={Auth(DetailPage, null)}/>
             <Route path="/journal" exact component={Auth(Journal, null)}/>
             <Route path="/chat" exact component={Auth(Chat, null)}/>
             <Route path="/students" exact component={Auth(Students, null)}/>
             <Route path="/plans" exact component={Auth(Plans, null)}/>
             <Route path="/plans/create" exact component={Auth(CreatePlan, null)}/>
             {/* <Route path="/logout" exact component={Auth(Logout, true)} /> */}
             {/* <Route path="/register" exact component={Auth(Register, false)} /> */}
             {/* <Route path="/about" exact component={Auth(About, null)} /> */}
             {/* <Route path="/contacts" exact component={Auth(Contacts, null)} /> */}
             {/* <Route path="/rules" exact component={Auth(Rules, null)} /> */}
             {/* <Route path="/profile" exact component={Auth(UserProfile, true)} /> */}
             {/* <Route path="/cart" exact component={Auth(Cart, true)} /> */}
             {/* <Route path="/products/:slug/:id" exact component={Auth(ProductsDetail, true)} /> */}
             {/* <Route path="/products/:slug" exact component={Auth(Products, null)} /> */}
             <Route path="/" exact component={Auth(Home, null)}/>
             <Route path="*" component={Auth(NotFound, null)}/>
          </Switch>
       </Layout>
   );
};

export default Routes;