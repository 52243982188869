import React from 'react';
import StatLeft from "./HomeStat/StatLeft";
import StatCenter from "./HomeStat/StatCenter";
import StatRight from "./HomeStat/StatRight";
import Index from "./HomeTable";
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";


class Home extends React.Component {
   render() {
      return (
          <main className="main">

             <MyHelmet
                 title="Система Дистанционного Управления"
                 content="Основная страница СДО (система дистанционного управления)"
             />

             <div className="container-fluid">
                <div className="row">
                   <div className="col-lg-12">

                      <div
                          className="home-statistics-wrapper pt-sm-2 pt-md-3 pt-lg-4 pt-xl-5"
                      >

                         <div className="row">
                            <StatLeft/>
                            <StatCenter/>
                            <StatRight/>
                         </div>

                         <div className="row">
                            <Index />
                         </div>

                      </div>

                   </div>
                </div>
             </div>
          </main>
      )
   }
}


export default Home;