import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {BsSearch} from "react-icons/bs"
import {FaGlobe} from "react-icons/fa"
import {FiLogOut} from "react-icons/fi"
import {Dropdown} from "react-bootstrap";

class Header extends Component {

   render() {
      return (
          <header>
             <div className="container-fluid">
                <div className="row">
                   <div className="col-12">

                      <div className="header">

                         <div className="search-header">
                            <div className="input-group border-bottom">
                               <input type="search" placeholder="Поиск" aria-describedby="search"
                                      className="form-control bg-none border-0"/>
                               <div className="input-group-append border-0">
                                  <button id="search" type="button" className="btnSearch">
                                     <BsSearch/>
                                  </button>
                               </div>
                            </div>
                         </div>

                         <div className="settings-header">
                            <div className="lang-head">
                               <div className="dropdown">
                                  <Dropdown>
                                     <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <FaGlobe /><span>Русский</span>
                                     </Dropdown.Toggle>
                                     <Dropdown.Menu>
                                        <Dropdown.Item>O'zbekcha</Dropdown.Item>
                                        <Dropdown.Item>English</Dropdown.Item>
                                     </Dropdown.Menu>
                                  </Dropdown>
                               </div>
                            </div>
                            <div className="account-head">
                               <Link to="/account">
                                  <img src="/images/avtech.png" alt=""/>
                                  <span>Пользователь</span>
                               </Link>
                            </div>
                            <div className="account-head">
                               <Link to="/login">
                                  <FiLogOut/>
                                  <span>Выход</span>
                               </Link>
                            </div>
                         </div>

                      </div>
                   </div>
                </div>
             </div>
          </header>
      );
   }
}

export default withRouter(Header);