import React from 'react';
import {withRouter} from "react-router-dom"
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

class Layout extends React.Component {

   state = {
      open: true,
      isSmallScreen: false
   };

   setOpen = (open) => {
      this.setState({open});
   };

   componentDidMount() {
      if(window.innerWidth < 1200) {
         this.setState({open: false, isSmallScreen: true});
      }
   }

   render() {

      if (this.props.location.pathname === "/login") {
         return this.props.children
      } else {

         return (
             <React.Fragment>
                <div className="container-fluid p-0">
                   <div className="row m-0">

                      <Sidebar
                          open={this.state.open}
                          screen={this.state.isSmallScreen}
                          setOpen={this.setOpen}
                      />
                      {/*'col-xl-2 col-lg-2 col-1' : 'col-xl-1 col-lg-2 col-2'*/}

                      <div
                          className={
                             this.state.open ? `offset-xl-2 col offset-lg-2`
                                 : `offset-xl-1 offset-2 col`
                          }
                      >
                         <Header/>
                         <section className="layout-children">
                            {
                               this.props.children
                            }
                         </section>

                         {
                            this.props.location.pathname.match(new RegExp('/subject', 'gi'))
                                ? null : <Footer/>
                         }
                      </div>

                   </div>
                </div>
             </React.Fragment>
         )
      }
   }
}

export default withRouter(Layout);