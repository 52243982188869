import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FiSettings} from "react-icons/fi";
import {BsPencilSquare} from "react-icons/bs";

class ListOfThemes extends Component {
   render() {
      return (
          <div className="container-fluid">
             <div className="row">
                <div className="col-lg-12">

                   <div className="p-sm-2 p-md-3 p-lg-4 p-xl-5">

                      <h4>Темы по предмету: Математика (2Б)</h4>

                      <table className="table">
                         <thead className="thead-light">
                         <tr>
                            <th scope="col">#</th>
                            <th scope="col"><BsPencilSquare/> Имя темы</th>
                            <th scope="col"><FiSettings/> Управление</th>
                         </tr>
                         </thead>
                         <tbody>
                         <tr>
                            <th scope="row">1</th>
                            <td>Аксиоматический метод</td>
                            <td><Link className="btn btn-outline-secondary"
                                      to="/subject/themeList/1">Редактировать</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">2</th>
                            <td>Формула Герона</td>
                            <td><Link className="btn btn-outline-secondary"
                                      to="/subject/themeList/1">Редактировать</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">3</th>
                            <td>Иррациональные неравенства и уравнения</td>
                            <td><Link className="btn btn-outline-secondary"
                                      to="/subject/themeList/1">Редактировать</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">4</th>
                            <td>Логарифмические квадратные уравнения</td>
                            <td><Link className="btn btn-outline-secondary"
                                      to="/subject/themeList/1">Редактировать</Link></td>
                         </tr>
                         </tbody>
                      </table>

                      <div>
                         <Link className="btn btn-secondary" to="/subject">Назад</Link>
                         <Link className="btn btn-outline-primary ml-5" to="/subject/createTheme/1">
                            Создать тему
                         </Link>
                      </div>

                   </div>
                </div>
             </div>
          </div>
      );
   }
}

export default ListOfThemes;