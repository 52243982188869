import React from 'react';
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

const NotFound = () => {
    return (
        <section className="not-found">
           <MyHelmet title="Страница не найдена" content="По вашему запросу ничего не найдено" />

           <h1>Ошибка: 404 Страница не найдена</h1>
        </section>
    );
};

export default NotFound;