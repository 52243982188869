import React from 'react';
import {FaAngleLeft, FaAngleRight} from "react-icons/fa"
import {Link} from "react-router-dom";
import SidebarList from "./List/SidebarList";

const Sidebar = (props) => {

   return (
       <aside className={`${props.open ? 'col-xl-2 col-lg-2 col-1' : 'col-xl-1 col-lg-2 col-2'} pl-0`}>
          <div className="sidebar-main">
             <div onClick={() => props.setOpen(!props.open)}
                  className={`${props.screen ? 'd-none' : 'sidebar-control'}`}
             >
                {props.open ? <FaAngleLeft/> : <FaAngleRight/>}
             </div>
             <div className="logo-main">
                <Link to="/">
                   {
                      props.open ?
                          <React.Fragment>
                             <img src="/images/avtech.png" alt="logo" />
                             <div className="d-flex align-items-center">
                               <span className="logo-text">
                                  Онлайн школа
                                  <span className="logo-desc">Админ панель</span>
                               </span>
                             </div>
                          </React.Fragment>
                          : <img src="/images/avtech.png" alt="logo" className="sidebar-opened" />
                   }

                </Link>
             </div>
             <div className="menu-main">
                <SidebarList open={props.open}/>
             </div>
          </div>
       </aside>
   );
};

export default Sidebar;