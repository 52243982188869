import React, {Component} from 'react';
import Timer from "../../widgets/Timer/timer";

class CodeApprove extends Component {

   state = {
      code: "",
      noTimeLeft: false
   };

   setTimeLeft = (isTimeLeft) => {
      this.setState({noTimeLeft: isTimeLeft})
   };

   setCode = (e) => {
      this.setState({
         code: e.target.value
      })
   };

   submitForm = (e) => {
      e.preventDefault()
   };

   render() {
      return (
          <React.Fragment>
             <form className="code-approve" onSubmit={this.submitForm}>
                <div className="form-group">
                   <label htmlFor="code">Код подтверждения был выслан в смс</label>
                   <div className="code-timer d-flex align-items-center">
                      <input
                          id="code"
                          className="input form-control"
                          type="text"
                          name="code"
                          placeholder="XXX-XXX"
                          value={this.state.code}
                          onChange={(e) => this.setCode(e)}
                          autoComplete="off"
                          disabled={this.state.noTimeLeft}
                      />
                      <span><Timer setTimeLeft={this.setTimeLeft} time={this.props.expTime} /></span>
                   </div>
                </div>
                <div className="d-flex p-2">
                   <span className="w-100">Не пришёл код?</span>
                   <button className="btn again">Отправить снова</button>
                </div>
                <button disabled={this.state.noTimeLeft} className="btn btn-success" type="submit">Подтвердить</button>
             </form>
          </React.Fragment>
      );
   }
}

export default CodeApprove;