import React, {Component} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";

class CreatePlan extends Component {
   state = {
      validated: false,
      formData: {
         questions: []
      }
   };

   handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }

      this.setState({validated: true});
   };

   render() {

      return (
          <div className="container-fluid">
             <div className="row">
                <div className="col-lg-12">

                   <div className="p-sm-2 p-md-3 p-lg-4 p-xl-5">
                      <h4>Создать поурочный план</h4>
                      <Form
                          noValidate
                          validated={this.state.validated}
                          onSubmit={this.handleSubmit}
                      >

                         <Form.Group controlId="choose-subject">
                            <Form.Control as="select" custom>
                               <option disabled value={0}>Выберите предмет</option>
                               <option value={1}>Алгебра</option>
                               <option value={2}>Геометрия</option>
                               <option value={3}>Математика</option>
                               <option value={4}>Дискретная математика</option>
                               <option value={5}>Линейная алгебра</option>
                            </Form.Control>
                         </Form.Group>

                         <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                               <Form.Label>Название Темы</Form.Label>
                               <Form.Control
                                   required
                                   type="text"
                                   placeholder="Тема"
                                   defaultValue=""
                               />
                               <Form.Control.Feedback type="invalid">
                                  Тема не может быть пустой
                               </Form.Control.Feedback>
                               <Form.Control.Feedback>Успех!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                               <Form.Label>Текст примечания</Form.Label>
                               <Form.Control
                                   as="textarea"
                                   rows={3}
                                   required
                                   type="textarea"
                                   placeholder="Примечание"
                                   defaultValue=""
                               />
                               <Form.Control.Feedback>Успех!</Form.Control.Feedback>
                            </Form.Group>
                         </Form.Row>

                         <Form.Row>

                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                               <Form.Label>Доступно для</Form.Label>
                               <div>
                                  <Form.Check inline label="1А" id="check-1" type="checkbox"/>
                                  <Form.Check inline label="2Б" id="check-2" type="checkbox"/>
                                  <Form.Check inline label="1Г" id="check-3" type="checkbox"/>
                                  <Form.Check inline label="1Е" id="check-4" type="checkbox"/>
                               </div>
                            </Form.Group>
                         </Form.Row>
                         <Link className="btn btn-secondary mr-5" to="/plans">Назад</Link>
                         <Button className="btn btn-primary" type="submit">Добавить</Button>
                      </Form>


                   </div>

                </div>
             </div>
          </div>
      );
   }
}

export default CreatePlan;