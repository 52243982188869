import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FaBook} from "react-icons/fa";
import {FiSettings} from "react-icons/fi";
import {MdSchool} from "react-icons/md";
import MyHelmet from "../../widgets/MyHelmet/MyHelmet";

class Subjects extends Component {
   render() {
      return (
          <div className="container-fluid">

             <MyHelmet title="Предметы" content="Страница предметов" />

             <div className="row">
                <div className="col-lg-12">

                   <div className="p-sm-2 p-md-3 p-lg-4 p-xl-5">
                      <h4>Предметы</h4>
                      <table className="table">
                         <thead className="thead-light">
                         <tr>
                            <th scope="col">#</th>
                            <th scope="col"><FaBook /> Наименование предмета</th>
                            <th scope="col"><MdSchool /> Класс</th>
                            <th scope="col"><FiSettings /> Управление</th>
                         </tr>
                         </thead>
                         <tbody>
                         <tr>
                            <th scope="row">1</th>
                            <td>Математика</td>
                            <td>1А</td>
                            <td><Link to="/subject/themeList/1">Подробнее</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">2</th>
                            <td>История</td>
                            <td>2Б</td>
                            <td><Link to="/subject/themeList/1">Подробнее</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">3</th>
                            <td>Химия</td>
                            <td>9Е</td>
                            <td><Link to="/subject/themeList/1">Подробнее</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">4</th>
                            <td>Биология</td>
                            <td>8Д</td>
                            <td><Link to="/subject/themeList/1">Подробнее</Link></td>
                         </tr>
                         <tr>
                            <th scope="row">5</th>
                            <td>Математика</td>
                            <td>7Г</td>
                            <td><Link to="/subject/themeList/1">Подробнее</Link></td>
                         </tr>
                         </tbody>
                      </table>
                   </div>

                </div>
             </div>
          </div>
      );
   }
}

export default Subjects;