import React from 'react';
import {BsFillClockFill} from "react-icons/bs"
import CountUp from 'react-countup';

const StatLeft = () => {
   return (
       <div className="stat-left col-xl-4 col-lg-12">
          <div className="row">
             <div className="wrap-stat mb-4 col-md-6">
                <div className="shade-block student-wrap">
                   <div className="img">
                      <img src="/images/students.svg" alt="boy girl"/>
                   </div>
                   <div className="d-flex">
                      <strong><CountUp end={76} /></strong>
                      <span>Мои ученики</span>
                   </div>
                </div>
             </div>
             <div className="wrap-stat mb-4 col-md-6">
                <div className="shade-block clock-wrap">
                   <div className="clk-svg">
                      <BsFillClockFill/>
                   </div>
                   <div className="d-flex">
                      <strong><CountUp end={18} /></strong>
                      <span>Часов / неделя</span>
                   </div>
                </div>
             </div>
             <div className="wrap-stat mb-4 col-md-6">
                <div className="shade-block student-wrap">
                   <div className="img">
                      <img src="/images/students.svg" alt="boy girl"/>
                   </div>
                   <div className="d-flex">
                      <strong><CountUp end={76} /></strong>
                      <span>Мои ученики</span>
                   </div>
                </div>
             </div>
             <div className="wrap-stat mb-4 col-md-6">
                <div className="shade-block clock-wrap">
                   <div className="clk-svg">
                      <BsFillClockFill/>
                   </div>
                   <div className="d-flex">
                      <strong><CountUp end={18}/></strong>
                      <span>Часов / неделя</span>
                   </div>
                </div>
             </div>
          </div>
       </div>
   );
};

export default StatLeft;