import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Form, Col, Button} from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-base64-upload-adapter";
import "ckeditor5-build-classic-base64-upload-adapter/build/translations/ru";

// import {Editor} from "react-draft-wysiwyg"
// import {EditorState, convertFromRaw, convertToRaw} from "draft-js"
// import {EditorState} from "draft-js"
// import {stateToHTML} from "draft-js-export-html";

class CreateTheme extends Component {
   state = {
      // editorState: EditorState.createEmpty(),
      html: "",
      validated: false,
      formData: {
         questions: []
      },
      data: ""
   };


   // uploadImageCallBack = (file) => {
   //
   //    console.log(file);
   //
   //    return new Promise(
   //        (resolve, reject) => {
   //           const xhr = new XMLHttpRequest();
   //           xhr.open('POST', 'https://api.imgur.com/3/image');
   //           xhr.setRequestHeader('Authorization', 'Client-ID 0661d5245a06604');
   //           const data = new FormData();
   //           data.append('image', file);
   //           xhr.send(data);
   //           xhr.addEventListener('load', () => {
   //              const response = JSON.parse(xhr.responseText);
   //              resolve(response);
   //           });
   //           xhr.addEventListener('error', () => {
   //              const error = JSON.parse(xhr.responseText);
   //              reject(error);
   //           });
   //        }
   //    );
   // };

   handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }

      this.setState({validated: true});
   };

   // onEditorStateChange = (editorState) => {
   //
   //    const options = {
   //       inlineStyles: {
   //          SUPERSCRIPT: {element: 'sup'},
   //          SUBSCRIPT: {element: 'sub'}
   //       },
   //       inlineStyleFn: (styles) => {
   //          let key = 'fontsize-';
   //          let fz = styles.filter(value => value.startsWith(key)).first();
   //          // let ta = styles.filter
   //
   //          if (fz) {
   //             return {
   //                element: 'span',
   //                style: {fontSize: fz.replace(key, '') + 'px'}
   //             }
   //          }
   //       },
   //       blockStyleFn: (block) => {
   //          const key = block.getData().get('text-align');
   //          if (key) {
   //             return {style: {textAlign: `${key}`}}
   //          }
   //       }
   //    };
   //
   //    let contentState = editorState.getCurrentContent();
   //    // let rowState = convertToRaw(contentState);
   //
   //    let html = stateToHTML(contentState, options);
   //
   //    // this.updateForm({id: 'body'}, html);
   //
   //    console.log(html)
   //
   //    this.setState({
   //       editorState,
   //       html: html
   //    });
   // };

   renderQuestions = (qlist) => (
       qlist.map((item, i) => (
           <React.Fragment key={i}>
              <Form.Group as={Col} md="6">
                 <Form.Label>Вопрос по теме</Form.Label>
                 <Form.Control
                     required
                     type="text"
                     placeholder="Вопрос"
                     defaultValue=""
                 />
                 <Form.Control.Feedback type="invalid">
                    Вопрос не может быть пустой
                 </Form.Control.Feedback>
                 <Form.Control.Feedback>Успех!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6">
                 <Form.Label>Правильный ответ</Form.Label>
                 <Form.Control as="select">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                 </Form.Control>
              </Form.Group>
           </React.Fragment>
       ))
   );


   addQuestion = () => {
      let newFormData = this.state.formData;
      const html = (
          <React.Fragment>
             <Form.Group as={Col} md="6">
                <Form.Label>Вопрос по теме</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Вопрос"
                    defaultValue=""
                />
                <Form.Control.Feedback type="invalid">
                   Вопрос не может быть пустой
                </Form.Control.Feedback>
                <Form.Control.Feedback>Успех!</Form.Control.Feedback>
             </Form.Group>

             <Form.Group as={Col} md="6">
                <Form.Label>Правильный ответ</Form.Label>
                <Form.Control as="select">
                   <option>1</option>
                   <option>2</option>
                   <option>3</option>
                   <option>4</option>
                   <option>5</option>
                </Form.Control>
             </Form.Group>
          </React.Fragment>
      );
      newFormData.questions.push(html);

      this.setState({formData: newFormData})

   };

   render() {

      return (
          <div className="container-fluid">
             <div className="row">
                <div className="col-lg-12">

                   <div className="p-sm-2 p-md-3 p-lg-4 p-xl-5">
                      <h4>Создать тему по предмету: Математика</h4>
                      <Form
                          className="create-theme-form"
                          noValidate
                          validated={this.state.validated}
                          onSubmit={this.handleSubmit}
                      >

                         <Form.Row className="form-row-custom-wrapper">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                               <Form.Label>Название Темы</Form.Label>
                               <Form.Control
                                   required
                                   type="text"
                                   placeholder="Тема"
                                   defaultValue=""
                               />
                               <Form.Control.Feedback type="invalid">
                                  Тема не может быть пустой
                               </Form.Control.Feedback>
                               <Form.Control.Feedback>Успех!</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom02">

                               {/*<div className="ckeditor-wrapper">*/}

                               <CKEditor
                                   editor={ClassicEditor}
                                   data={this.state.data}
                                   config={{
                                      language: 'ru',
                                      image: {
                                         // Configure the available styles.
                                         resizeUnit: "%",
                                         styles: [
                                            'alignLeft', 'alignCenter', 'alignRight'
                                         ],

                                         // Configure the available image resize options.
                                         resizeOptions: [
                                            {
                                               name: 'imageResize:original',
                                               label: 'Оригинал',
                                               value: null
                                            },
                                            {
                                               name: 'imageResize:50',
                                               label: '50%',
                                               value: '50'
                                            },
                                            {
                                               name: 'imageResize:75',
                                               label: '75%',
                                               value: '75'
                                            }
                                         ],

                                         // You need to configure the image toolbar, too, so it shows the new style
                                         // buttons as well as the resize buttons.
                                         toolbar: [
                                            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                                            '|',
                                            'imageResize',
                                            '|',
                                            'imageTextAlternative'
                                         ]
                                      }
                                   }}
                                   // onInit={ editor => {
                                   //    // You can store the "editor" and use when it is needed.
                                   //    console.log( 'Editor is ready to use!', editor );
                                   // } }
                                   onChange={(event, editor) => {
                                      const data = editor.getData();
                                      console.log(data)
                                      this.setState({data})
                                   }}
                                   // onBlur={ ( event, editor ) => {
                                   //    console.log( 'Blur.', editor );
                                   // } }
                                   // onFocus={ ( event, editor ) => {
                                   //    console.log( 'Focus.', editor );
                                   // } }
                               />
                               {/*</div>*/}

                               {/*<Editor*/}
                               {/*    toolbar={{*/}
                               {/*       options: ['inline', 'blockType', 'image', 'fontSize', 'list', 'link', 'textAlign', 'history'],*/}
                               {/*       inline: { inDropdown: true },*/}
                               {/*       list: { inDropdown: true },*/}
                               {/*       // textAlign: { inDropdown: true },*/}
                               {/*       link: { inDropdown: true },*/}
                               {/*       image: { uploadCallback: this.uploadImageCallBack, alt: { present: true, mandatory: true } }*/}
                               {/*    }}*/}

                               {/*    editorState={this.state.editorState}*/}
                               {/*    wrapperClassName="my-editor-wrapper"*/}
                               {/*    editorClassName="my-editor-editor"*/}
                               {/*    onEditorStateChange={this.onEditorStateChange}*/}
                               {/*    localization={{*/}
                               {/*       locale: 'ru'*/}
                               {/*    }}*/}
                               {/*/>*/}
                            </Form.Group>
                         </Form.Row>

                         <Form.Row>

                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                               <Form.Label>Доступно для</Form.Label>
                               <div>
                                  <Form.Check inline label="1А" id="check-1" type="checkbox"/>
                                  <Form.Check inline label="2Б" id="check-2" type="checkbox"/>
                                  <Form.Check inline label="1Г" id="check-3" type="checkbox"/>
                                  <Form.Check inline label="1Е" id="check-4" type="checkbox"/>
                               </div>
                            </Form.Group>


                            <Form.Group as={Col} md="6" controlId="validationFormik107">
                               <Form.Label>Файл для загрузки</Form.Label>
                               <Form.File
                                   className="position-relative"
                                   name="file"
                                   label="Файл для загрузки"
                                   data-browse="Загрузить"
                                   custom
                                   // onChange={handleChange}
                                   // isInvalid={!!errors.file}
                                   // feedback={errors.file}
                                   id="validationFormik107"
                               />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                               <Form.Label>Вопрос по теме</Form.Label>
                               <Form.Control
                                   required
                                   type="text"
                                   placeholder="Вопрос"
                                   defaultValue=""
                               />
                               <Form.Control.Feedback type="invalid">
                                  Вопрос не может быть пустой
                               </Form.Control.Feedback>
                               <Form.Control.Feedback>Успех!</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                               <Form.Label>Правильный ответ</Form.Label>
                               <Form.Control as="select">
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                               </Form.Control>
                            </Form.Group>

                            {
                               this.renderQuestions(this.state.formData.questions)
                            }

                            <div
                                className="btn btn-light mb-3"
                                onClick={() => this.addQuestion()}
                            >
                               Добавить вопрос
                            </div>
                         </Form.Row>

                         <Form.Row>

                            <Form.Group as={Col} controlId="formGridZip">
                               <Form.Label>Проходной балл в %</Form.Label>
                               <Form.Control type="number" min={1} max={100}/>
                               <Form.Control.Feedback type="invalid">Максимальный балл 100%</Form.Control.Feedback>
                            </Form.Group>
                         </Form.Row>
                      </Form>

                      <Link className="btn btn-secondary mr-5" to="/subject/themeList/1">Назад</Link>

                      <Button className="btn btn-primary" type="submit">Сохранить</Button>
                   </div>

                   {/*<div className="body-text"*/}
                   {/*     dangerouslySetInnerHTML={{*/}
                   {/*        __html: this.state.html*/}
                   {/*     }}*/}
                   {/*>*/}
                   {/*</div>*/}

                </div>
             </div>
          </div>
      );
   }
}

export default CreateTheme;

