import React, {useState} from 'react';
import {FaPhone, FaLock} from "react-icons/fa";
import {connect} from "react-redux";
import Register from "../Register/Register";

const LoginForm = () => {

   const [phone, setPhone] = useState("");
   const [password, setPassword] = useState("");
   const [isOpen, setOpen] = useState(false);

   const submitForm = (e) => {
      e.preventDefault();
   };

   return (
       <React.Fragment>
          <form onSubmit={submitForm}>
             <div className="wrap-input validate-input">
                <input
                    className="input"
                    minLength={12}
                    maxLength={12}
                    type="text"
                    name="username"
                    placeholder="Номер телефона"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    // autoComplete="off"
                />
                <FaPhone className={"focus-input user"}/>
             </div>

             <div className="wrap-input validate-input">
                <input
                    className="input"
                    type="password"
                    name="pass"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FaLock className={"focus-input password"}/>
             </div>

             <div className="container-login-form-btn">
                <button className="login-form-btn">
                   Вход
                </button>
             </div>

             <div className="container-login-form-lnk">
                <button>
                   Забыли пароль?
                </button>
                <button onClick={() => setOpen(true)}>
                   Регистрация
                </button>
             </div>
          </form>
          {isOpen ? <Register isOpen={isOpen} setOpen={setOpen} /> : null}
       </React.Fragment>
   );
};

function mapStateToProps(state) {
   return {
      user: state.user_r
   }
}

export default connect(mapStateToProps)(LoginForm);