import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {MdDateRange} from "react-icons/md"
import {BsPeopleCircle} from "react-icons/bs"
import {FiUpload, FiSettings} from "react-icons/fi"

class DetailList extends Component {
   render() {
      return (
          <div className="container-fluid">
             <div className="row">
                <div className="col-lg-12">

                   <div className="p-sm-2 p-md-3 p-lg-4 p-xl-5">

                      <h4>Ученики</h4>
                      <div className="hw-detail-table">

                         <table className="table">
                            <thead className="thead-light">
                            <tr>
                               <th scope="col">#</th>
                               <th scope="col"><BsPeopleCircle/> Ф.И.О</th>
                               <th scope="col"><FiUpload/>  Статус ДЗ</th>
                               <th scope="col"><MdDateRange/> Дата загрузки</th>
                               <th scope="col"><FiSettings/> Управление</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                               <th scope="row">1</th>
                               <td>Шокиров Исмоил Шавкатович</td>
                               <td>Загружено</td>
                               <td>02.05.2020</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">2</th>
                               <td>Акборов Комилжон Авазович</td>
                               <td>Не загружено</td>
                               <td>Не определено</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">3</th>
                               <td>Дониёрова Ирода Фазилжановна</td>
                               <td>Не загружено</td>
                               <td>Не определено</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">4</th>
                               <td>Александров Лев Матвеевич</td>
                               <td>Не загружено</td>
                               <td>Не определено</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">5</th>
                               <td>Быкова Ирина Тимофеевна</td>
                               <td>Загружено</td>
                               <td>18.08.2020</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">6</th>
                               <td>Бородина Анна Романовна</td>
                               <td>Не загружено</td>
                               <td>Не определено</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">7</th>
                               <td>Борисов Мирослав Ярославович</td>
                               <td>Загружено</td>
                               <td>17.07.2020</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">8</th>
                               <td>Бессонов Илья Кириллович</td>
                               <td>Загружено</td>
                               <td>18.09.2020</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">9</th>
                               <td>Афанасьева Варвара Георгиевна</td>
                               <td>Загружено</td>
                               <td>08.05.2020</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">10</th>
                               <td>Анисимов Артём Кириллович</td>
                               <td>Не загружено</td>
                               <td>Не определено</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            <tr>
                               <th scope="row">11</th>
                               <td>Лаптева Анна Степановна</td>
                               <td>Загружено</td>
                               <td>14.07.2020</td>
                               <td>
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/homework/list/1/detail/1"
                                  >
                                     Просмотреть
                                  </Link>
                               </td>
                            </tr>
                            </tbody>
                         </table>
                      </div>

                      <Link className="btn btn-secondary mt-3" to="/homework">
                         Назад
                      </Link>

                   </div>

                </div>
             </div>
          </div>
      );
   }
}

export default DetailList;